<template>
  <!-- buyer login page  -->
  <section class="user-register my-5">
    <b-container>
      <div class="user-register-form">
        <b-row class="justify-content-center">
          <b-col lg="12">
            <div class="register-info" v-if="b2b_buyer_registration">
              <h4 class="main-header">{{ $t("register.mainInformation") }}</h4>
              <div class="user-login-form">
                <b-button
                  :to="`${isDoctor ? '/doctor-register' : '/b2b-register'}`"
                  class="login-button my-2 border-main"
                >
                  <b>{{ $t("register.haveNotAccount") }}</b>
                </b-button>
              </div>
            </div>
            <form
              @submit.prevent="login()"
              class="b2bLoginForm"
              id="b2bLoginForm"
            >
              <b-row class="justify-content-center">
                <!-- Email -->
                <b-col lg="12">
                  <b-form-group>
                    <label for="email">{{ $t("register.email") }}</label>
                    <span class="requried">*</span>
                    <b-form-input
                      type="email"
                      id="email"
                      v-model="form.email"
                    />
                    <div
                      class="error"
                      v-for="(error, index) in errors.email"
                      :key="index"
                    >
                      {{ error }}
                    </div>
                  </b-form-group>
                </b-col>
                <!-- Password -->
                <b-col lg="12">
                  <b-form-group>
                    <label for="password">{{ $t("register.password") }}</label>
                    <span class="requried">*</span>
                    <div class="show-password">
                      <b-form-input
                        id="password"
                        v-model="form.password"
                        :type="fieldType"
                      />
                      <div class="icon-passowrd" @click.stop="switchField()">
                        <font-awesome-icon
                          icon="fa-solid fa-eye"
                          v-if="fieldType === 'password'"
                          size="lg"
                        />
                        <font-awesome-icon
                          icon="fa-solid fa-eye-slash"
                          v-else
                          size="lg"
                        />
                      </div>
                    </div>
                    <div
                      class="error"
                      v-for="(error, index) in errors.password"
                      :key="index"
                    >
                      {{ error }}
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>

              <router-link
                to="/forget-password"
                v-if="buyerUserData && buyerUserData.type === 'b2c'"
              >
                <b class="forget-password my-3" v-b-modal.B2BForgetPassword>
                  {{ $t("login.fogetPassword") }}
                </b>
              </router-link>
              <a v-b-modal.B2BForgetPassword v-else>
                <b class="forget-password my-3">
                  {{ $t("login.fogetPassword") }}
                </b>
              </a>

              <RecapchaComponent @psssrecaptcha="recaptchaValue($event)" />
              <div class="submition-box">
                <b-button type="submit" variant="danger">
                  {{ $t("register.submit") }}
                </b-button>
              </div>
            </form>
          </b-col>
        </b-row>
      </div>
      <b-modal
        id="B2BForgetPassword"
        :title="$t('login.resetPassword')"
        no-close-on-backdrop
        no-close-on-esc
        ref="b2cLogin"
      >
        <form>
          <b-form-group>
            <label for="email">{{ $t("register.email") }}</label>
            <span class="requried">*</span>
            <b-form-input id="email" v-model="emailForget" maxlength="100" />
            <div
              class="error"
              v-for="(error, index) in errors.email"
              :key="index"
            >
              {{ error }}
            </div>
          </b-form-group>
        </form>
        <div slot="modal-footer" class="d-flex">
          <a @click="sendEmail()" class="reset-Link" type="submit">{{
            $t("login.reset")
          }}</a>
        </div>
      </b-modal>
    </b-container>
  </section>
</template>
<script>
import auth from "@/services/auth";
import RecapchaComponent from "@/components/Recaptcha/RecaptchaCompoent.vue";
export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
        token: "",
        device_type: "web",
        recaptcha: "",
      },
      errorMsg: "",
      fieldType: "password",
      errors: {},
      emailForget: "",
    };
  },
  methods: {
    recaptchaValue($event) {
      this.form.recaptcha = $event;
    },
    /**
     * @vuese
     * This is a public
     */
    login() {
      // localStorage.clear();
      localStorage.removeItem("userInfo");
      localStorage.removeItem("globalAddressUUID");
      localStorage.removeItem("buyerUserData");

      let loginData = {
        email: this.form.email,
        password: this.form.password,
        token: this.firebaseToken,
        device_type: this.form.device_type,
        recaptcha: this.form.recaptcha,
        callback_url: `${this.mainDoamin}CheckUserValidity`,
      };
      localStorage.setItem("portal", "buyer");
      auth
        .login("buyer", loginData)
        .then(async (res) => {
          localStorage.setItem("userInfo", JSON.stringify(res.data.items));

          if (!res.data.items.item.email_verified_at) {
            localStorage.setItem("massege", this.$t("register.openEmail"));
            localStorage.removeItem("guest-id");
            await this.$router.replace(
              {
                // path: "/profile/account-information-b2b",
                path: "/",
              },

              () => {
                this.$router.go(0);
              }
            );
            await location.reload()
          } else if (!res.data.items.item.mobile_verified_at) {
            localStorage.setItem("massege", this.$t("register.otpVerify"));
            localStorage.removeItem("guest-id");
            await this.$router.replace(
              {
                // path: "/profile/account-information-b2b",
                path: "/",
              },

              () => {
                this.$router.go(0);
              }
            );
            await location.reload()
          } else {
            localStorage.setItem("massege", "");
            localStorage.removeItem("guest-id");
            this.$router.replace(
              {
                path: "/",
              },

              () => {
                this.$router.go(0);
              }
            );
          }
        })
        .catch((error) => {
          const err = Object.values(error)[2].data;
          this.errors = err.items;
          this.errMsg(err.message);
        });
    },
    /**
     * @vuese
     * switchField function to show password
     */
    switchField() {
      this.fieldType = this.fieldType === "password" ? "text" : "password";
    },
    sendEmail() {
      const payload = {
        email: this.emailForget,
        callback_url: `${this.mainDoamin}Forget-Password`,
        type: "buyer",
      };
      auth
        .sendEmail(payload)
        .then((res) => {
          this.sucessMsg(res.data.message);
          this.$bvModal.hide("ForgetPassword");
        })
        .catch((error) => {
          const err = Object.values(error)[2].data;
          this.errors = err.items;
          this.errMsg(err.message);
        });
    },
  },
  computed: {
    /**
     * @vuese
     * get firebaseToken
     */
    firebaseToken() {
      return this.$store.state.firebaseToken;
    },
    isDoctor() {
      return this.$route?.path?.includes("doctor");
    },
  },
  components: {
    RecapchaComponent,
  },
  metaInfo: {
    title: "Login Page",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: `${localStorage.getItem("lang")}`,
      amp: true,
    },
  },
  mounted() {
    if (!this.b2b_status) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped>
.user-register {
  .main-title {
    text-align: center;
    padding: 30px 0;
  }

  .user-register-form {
    .register-info {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 25px;

      .back {
        font-weight: 500;
        color: $header-color;

        &:hover {
          color: $main-color;
        }
      }
    }

    .submition-box {
      text-align: center;
      padding: 20px;
      button {
        width: 30%;
        padding: 14px;
        background: $main-color !important;
        color: #fff;
        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }
}

.forget-password {
  display: block;
  font-weight: 500;
  color: $header-color;
  background-color: transparent;
  border: none;

  &:hover {
    color: $main-color;
  }
}

// style arabic
html:lang(ar) {
  .user-register {
    .user-register-form {
      text-align: right;
    }
  }
}
.reset-Link {
  color: #ffffff;
  background-color: $main-color;
  padding: 0.5rem 1.3rem;
  width: 100%;
}
</style>
